<template>
  <div>
    <v-card max-width="1400px">
      <div class="mx-10 pt-5 mb-10">
        <h2
          style="
            text-align: center;
            font: normal normal bold 20px/30px Noto Sans;
            letter-spacing: 0px;
            color: #000000 !important;
          "
          class="my-2"
        >
          検索条件
        </h2>
        <h2 class="text--title my-4">基本情報</h2>

        <!-- Basic information -->
        <div>
          <v-row>
            <v-col cols="2" class="float-left">
              <v-select
                label="法人個人区分"
                color="#000000"
                dense
                hide-details
                :items="itemIncludeCompanyResponseAll"
                item-value="id"
                item-text="name"
                v-model="getIncludeCompanyResponseAll"
                class="custom-input-label"
              ></v-select>
            </v-col>
            <v-col cols="6" class="float-left d-flex justify-space-between">
              <v-text-field
                color="#000000"
                dense
                :rules="[$rules.clientIdsComma]"
                v-model="clientIdsText"
                label="顧客番号(コンマ,区切りで複数検索)"
                class="custom-input-label"
              >
              </v-text-field>
              <!-- <h5 class="mt-3 ml-2">から</h5> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="float-left d-flex justify-space-between">
              <v-text-field
                color="#000000"
                dense
                hide-details
                class="v-text-field-number custom-input-label"
                @keydown="check"
                v-model="getAgeFromResponseAll"
                type="number"
                label="年齢"
              >
              </v-text-field>
              <h5 class="mt-3 ml-2 from">から</h5>
            </v-col>
            <v-col cols="2" class="float-left d-flex justify-space-between">
              <v-text-field
                color="#000000"
                dense
                hide-details
                class="v-text-field-number custom-input-label"
                type="number"
                @keydown="check"
                v-model="getAgeToResponseAll"
                label="年齢"
              >
              </v-text-field>
              <h5 class="mt-3 ml-2 from">まで</h5>
            </v-col>
            <v-col cols="3" class="float-left d-flex justify-space-between">
              <v-select
                dense
                hide-details
                :items="[
                  { id: null, name: '指定なし' },
                  { id: 'Male', name: '男' },
                  { id: 'Female', name: '女' },
                  { id: 'Other', name: 'その他' },
                ]"
                item-text="name"
                item-value="id"
                v-model="getSexResponseAll"
                label="性別"
                color="#000000"
                class="custom-input-label"
              ></v-select>
            </v-col>
          </v-row>
          <v-card class="mt-10 px-5 py-3" outlined max-width="95%">
            <div class="list-all-container my-10">
              <v-row>
                <v-col cols="6" class="float-left">
                  <v-row>
                    <v-col cols="6" class="float-left">
                      <v-select
                        color="#000000"
                        class="custom-input-label"
                        dense
                        hide-details
                        :items="[
                          { id: null, name: '全て' },
                          {
                            id: 'HomeOrAddress',
                            name: '自宅（個人）会社情報（法人）',
                          },
                          {
                            id: 'WorkplaceOrNotary',
                            name: '勤務先(個人)',
                          },
                          { id: 'Contact', name: '連絡先（個人・法人）' },
                          { id: 'Other', name: 'その他（個人・法人）' },
                        ]"
                        item-value="id"
                        item-text="name"
                        v-model="getClientContactTypeResponseAll"
                        label="連絡先発送先"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="float-left">
                      <v-text-field
                        dense
                        ref="postalCode"
                        v-model="getContactPostalCodesResponseAll"
                        placeholder="郵便番号(コンマ,区切りで複数検索)"
                        color="#000000"
                        :rules="rules.check_postalCode"
                        type="number"
                        class="
                          custom-input-label
                          text-field
                          v-text-field-number
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="float-left">
                      <v-select
                        dense
                        hide-details
                        v-model="getContactPrefecturesResponseAll"
                        :items="getEnumPrefeList"
                        item-text="name"
                        multiple
                        label="都道府県"
                        color="#000000"
                        class="custom-input-label"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="float-left">
                      <v-text-field
                        dense
                        hide-details
                        v-model="getContactLocalitiesResponseAll"
                        label="市区郡(コンマ,区切りで複数検索)"
                        color="#000000"
                        class="custom-input-label"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>
        <!-- End Basic information -->

        <!-- Prospect information -->
        <div class="py-3">
          <h2 class="text--title my-4">見込客情報</h2>
          <v-row>
            <v-col cols="4" class="float-left">
              <v-select
                dense
                hide-details
                v-model="getPurchaseRatesResponseAllSelect"
                item-text="name"
                item-value="id"
                multiple
                :items="enumPurchaseRateListResponseAll"
                label="購入評価"
                color="#000000"
                class="custom-input-label"
              ></v-select>
            </v-col>
            <v-col cols="2" class="float-left">
              <v-select
                dense
                hide-details
                :items="picIdsResponseAll"
                multiple
                v-model="getPicIdsResponseAll"
                item-text="name"
                item-value="id"
                label="営業担当"
                color="#000000"
                class="custom-input-label"
              ></v-select>
            </v-col>
          </v-row>
          <v-checkbox
            v-model="getIncludeDoNotContactResponseAll"
            label="折衝不可を含める"
            class="custom-checkbox-label"
          ></v-checkbox>
          <v-row class="d-flex">
            <v-col cols="5" class="float-left d-flex justify-start">
              <DatePickerSelectFromTo
                :dates="dateLastResponseDateFrom"
                :dateFrom="dateLastResponseDateFrom[0].date"
                :dateTo="dateLastResponseDateFrom[1].date"
                @change="updatLastResponseDate"
                :attrTagInput="{
                  dense: true,
                  'hide-details': true,
                  label: '反響日',
                  className: 'color-calender',
                  type: 'text-field',
                  hasBtnResetDatePicker: true,
                }"
                :icon="true"
                @reset="updatLastResponseDate"
              ></DatePickerSelectFromTo>
            </v-col>
          </v-row>
        </div>
        <!-- end prospect information -->

        <!-- Member information -->
        <div class="list-all-container py-3">
          <h2 class="text--title my-4">会員情報</h2>
          <v-row>
            <v-col cols="6" class="float-left d-flex justify-space-between">
              <v-text-field
                dense
                hide-details
                v-model="getMemberIdsApplicant"
                label="会員番号(コンマ,区切りで複数検索)"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex">
            <v-col cols="3" class="float-left">
              <v-select
                dense
                hide-details
                v-model="getMembershipStatusResponseAll"
                item-text="name"
                item-value="id"
                :items="[
                  { id: 'NeverHadContract', name: '退会済みを含まない' },
                  { id: 'NoCurrent', name: '退会済みを含む' },
                  { id: 'OnlyExMember', name: '退会済みのみ' },
                ]"
                label="退会状態"
                color="#000000"
                class="custom-input-label"
              ></v-select>
            </v-col>
            <v-col cols="5" class="float-left d-flex justify-start">
              <div v-for="item in dateMemberInfor" :key="item.id">
                <v-menu
                  v-model="menuDateMemberInfor[dateMemberInfor.indexOf(item)]"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <div class="d-flex justify-start ml-5">
                      <DatePickerComponent
                        type="text-field"
                        className="color-calender"
                        :date="item.date"
                        :attr="{
                          dense: true,
                          'hide-details': true,
                          label: '退会日',
                        }"
                        :action="on"
                        @reset="
                          (item.date = ''),
                            updateMembershipCancelDate({ id: 1, date: '' })
                        "
                        :hasBtnResetDatePicker="true"
                        :title="item.date"
                      ></DatePickerComponent>
                      <!-- <v-text-field
                        color="#000000"
                        class="custom-input-label"
                        v-model="item.date"
                        prepend-icon="mdi-calendar-month"
                        @change="updateMembershipCancelDate(item)"
                        dense
                        hide-details
                        label="退会日"
                        v-on="on"
                      ></v-text-field> -->
                      <h5 v-if="item.id === 1" class="mt-3 ml-2 from">から</h5>
                      <h5 v-else class="mt-3 ml-2 from">まで</h5>
                    </div>
                  </template>
                  <v-date-picker
                    v-if="item.id === 1"
                    v-model="item.date"
                    :first-day-of-week="0"
                    :locale="$i18n.locale"
                    scrollable
                    @change="updateMembershipCancelDate(item)"
                    @input="
                      menuMembershipCancellationDate[
                        dateMenuMembershipCancellationDate.indexOf(item)
                      ] = false
                    "
                    class="v-date-picker-custom"
                  ></v-date-picker>
                  <!--  -->

                  <!-- Date to -->
                  <v-date-picker
                    v-else
                    v-model="item.date"
                    :first-day-of-week="0"
                    :min="dateMenuMembershipCancellationDate[0].date"
                    :locale="$i18n.locale"
                    scrollable
                    @change="updateMembershipCancelDate(item)"
                    @input="
                      menuMembershipCancellationDate[
                        dateMenuMembershipCancellationDate.indexOf(item)
                      ] = false
                    "
                    class="v-date-picker-custom"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>
          </v-row>
        </div>
        <!-- End member information -->

        <!-- Activity information -->
        <div class="list-all-container py-3">
          <h2 class="text--title my-4">活動情報</h2>
          <v-row class="d-flex">
            <v-col cols="5" class="float-left d-flex justify-start">
              <DatePickerSelectFromTo
                :dates="dateActivityInformation"
                :dateFrom="dateActivityInformation[0].date"
                :dateTo="dateActivityInformation[1].date"
                @change="updatLastActionDate"
                :attrTagInput="{
                  dense: true,
                  'hide-details': true,
                  label: '折衝日',
                  className: 'color-calender',
                  type: 'text-field',
                  hasBtnResetDatePicker: true,
                }"
                :icon="true"
                @reset="updatLastActionDate"
              ></DatePickerSelectFromTo>
            </v-col>
            <v-col cols="3" class="float-left d-flex justify-space-between">
              <v-select
                color="#000000"
                class="custom-input-label"
                dense
                hide-details
                v-model="getEnumClientActionMethodListResponseAll"
                item-text="name"
                item-value="id"
                multiple
                :items="enumClientActionMethodListApplicant"
                label="活動情報"
              ></v-select>
            </v-col>
            <v-col cols="2" class="float-left d-flex justify-space-between">
              <v-select
                color="#000000"
                class="custom-input-label"
                dense
                hide-details
                v-model="getHasActionMethodIdsTypeResponseAll"
                :items="itemHasActionMethodIdsType"
                item-value="id"
                item-text="name"
                label="を全て含む"
              ></v-select>
            </v-col>
            <v-col cols="2" class="float-left d-flex justify-space-between">
              <v-select
                dense
                hide-details
                v-model="actionWithActionType"
                item-text="name"
                item-value="id"
                :items="[
                  { id: null, name: '指定なし' },
                  { id: true, name: '対応内容あり' },
                  { id: false, name: '対応内容なし' },
                ]"
                label="対応内容"
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <!-- End activity information -->

        <!-- Advertising measures -->
        <div class="list-all-container py-3">
          <h2 class="text--title my-4">広告施策(顧客)</h2>
          <v-row class="d-flex">
            <v-col cols="4" class="float-left d-flex justify-space-between">
              <v-dialog
                v-model="dialog"
                width="1330px"
                class="advertising-measures-customer-dialog"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-combobox
                    color="#000000"
                    label="広告施策名"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    class="combobox custom-input-label"
                    :value="selectedList"
                  ></v-combobox>
                </template>
                <v-card height="auto" style="padding-bottom: 1rem">
                  <AdvertisingMeasuresCustomersList
                    @close="dialog = false"
                    :selectedList="selectedList"
                    @onSave="checkSelectedList"
                  />
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row class="d-flex">
            <v-col cols="4" class="float-left d-flex justify-space-between">
              <v-select
                color="#000000"
                class="custom-input-label"
                dense
                v-model="getListCampaignGroupsResponseAll"
                hide-details
                :items="enumCampaignGroupListResponseAll"
                item-value="name"
                multiple
                item-text="name"
                label="広告施策タグ"
              ></v-select>
            </v-col>
            <v-col cols="3" class="float-left d-flex justify-space-between">
              <v-select
                color="#000000"
                class="custom-input-label"
                dense
                hide-details
                item-value="id"
                item-text="name"
                v-model="getItemCampaignTimesResponseAll"
                :items="itemCampaignTimes"
                label="広告施策回数"
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <!-- End advertising measures -->

        <!-- Experience reservation -->
        <div class="list-all-container py-3">
          <h2 class="text--title my-4">体験予約</h2>
          <v-row class="d-flex">
            <v-col cols="3" class="float-left d-flex justify-space-between">
              <v-select
                color="#000000"
                class="custom-input-label"
                dense
                hide-details
                v-model="getHasTrialStayResponseAll"
                item-text="name"
                item-value="id"
                :items="itemHasTrialStayResponseAll"
                label="体験予約"
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <!-- End experience reservation -->

        <!-- Customer information Remarks -->
        <div class="list-all-container py-3">
          <h2 class="text--title my-4">顧客情報備考</h2>
          <v-row class="d-flex">
            <v-col cols="5" class="float-left d-flex justify-space-between">
              <v-text-field
                color="#000000"
                class="custom-input-label"
                v-model="getRemarksResponseAll"
                dense
                hide-details
                label="顧客情報備考"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <!-- End customer information Remarks -->

        <!-- Document request -->
        <div class="list-all-container py-3">
          <h2 class="text--title my-4">資料請求</h2>
          <v-row class="d-flex">
            <v-col cols="3" class="float-left d-flex justify-space-between">
              <v-select
                color="#000000"
                class="custom-input-label"
                dense
                hide-details
                v-model="getBrochureRequetTypeResponseAll"
                item-value="id"
                item-text="name"
                :items="[
                  { id: null, name: '指定なし' },
                  { id: 'VSOnly', name: 'VSのみ' },
                  { id: 'VMOnly', name: 'VMのみ' },
                  { id: 'VSOrVM', name: 'VSかVMいずれか' },
                  { id: 'VSAndVM', name: 'VSとVM両方' },
                ]"
                label="資料請求"
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <!-- End Document request -->
        <div class="d-flex justify-center  fixed-bottom">
          <v-btn
            href="#table"
            @click="() => { $refs.table.resetPageAndSearch() }"
            class="t-btn--prm px-16 my-5 ctrl-enter-click"
          >
            <span class="search-btn-text">検索</span>
          </v-btn>
        </div>
      </div>
    </v-card>
    <div class="mb-16">
      <div class="d-flex justify-start mb-2">
        <v-btn
          :disabled="!checkPerUser"
          outlined
          small
          class="btn-crm-primary"
          @click="downloadCsv()"
        >
          <span class="outlined-btn-text">選択項目をCSV出力</span>
        </v-btn>
        <v-btn
          :disabled="!checkPerUser"
          outlined
          small
          class="btn-crm-primary ml-5"
          @click="setDefaultData()"
        >
          <span class="outlined-btn-text">選択項目をラベル印刷</span>
        </v-btn>
      </div>
        <client-search-table
          ref="table"
          :items="clientSearchPaginatedResponseAll"
          :funReset="loadList"
          :total="totalCount"
          @setSelected="(v) => {selected = v}"
        />
    </div>

    <label-printing
      @change-arrIdEndPage="checkIdStartPage"
      :visible="visible === 1"
      :selected="selected"
      @close="visible = -1"
    ></label-printing>
    <!-- End Open PDF in new tab -->
  </div>
</template>

<script>
import AdvertisingMeasuresCustomersList from '../AdvertisingMeasuresCustomers/AdvertisingMeasuresCustomersList.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { checkNumber } from '@/utils/validate';
import { getAgeFromDate } from '@/utils/dateUtil';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import LabelPrinting from '@/views/customer/applicant/LabelPrinting.vue';
import gql from 'graphql-tag';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import { downloadFile } from '@/utils/download';
import DatePickerComponent from '@/components/Input/DatePicker.vue';
import DatePickerSelectFromTo from '@/components/Input/datePicker/DatePickerSelectFromTo.vue';
import ClientSearchTable from '../../common/ClientSearchTable.vue';

export default {
  name: 'prospect-list-all',
  components: {
    AdvertisingMeasuresCustomersList,
    LabelPrinting,
    DatePickerComponent,
    DatePickerSelectFromTo,
    ClientSearchTable
  },
  data() {
    return {
      filter: {
        memberIds: []
      },
      dataSelect: [],
      arrIdEndPage: [],
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      rules: {
        min: [value => checkNumber(value)],
        check_postalCode: [
          v =>
            this.checkPostalCode(v) ||
            this.$t('rules.includeNCharacters', { value: 7 }),
        ],
      },
      visible: -1,
      menuLastResponseDateFrom: [],
      dateLastResponseDateFrom: [
        { id: 1, date: '' },
        { id: 2, date: '' },
      ],
      menuMembershipCancellationDate: [],
      dateMenuMembershipCancellationDate: [
        { id: 1, date: '' },
        { id: 2, date: '' },
      ],
      // Activity information
      menuActivityInformation: [],
      dateActivityInformation: [
        { id: 1, date: '' },
        { id: 2, date: '' },
      ],
      itemHasActionMethodIdsType: [
        { id: 'Any', name: 'のいずれかを含む' },
        { id: 'All', name: 'を全て含む' },
      ],
      itemHasTrialStayResponseAll: [
        { id: null, name: '指定なし' },
        { id: true, name: '体験予約あり' },
        { id: false, name: '体験予約なし' },
      ],
      itemContactPrefectures: [{ name: '千葉県' }, { name: '岐阜県' }],
      itemIncludeCompanyResponseAll: [
        { id: true, name: '指定なし' },
        { id: null, name: '法人のみ' },
        { id: false, name: '個人のみ' },
      ],
      menuDateMemberInfor: [],
      dateMemberInfor: [
        { id: 1, date: '' },
        { id: 2, date: '' },
      ],
      itemCampaignTimes: [
        { id: null, name: '指定なし' },
        { id: 'MoreThanOnce', name: '２回以上' },
        { id: 'Once', name: '初回' },
      ],
      totalCount: 0,
      ready: false,
      dialog: false,
      selected: [],
      selectedList: [],
      clientIdsText: '',
    };
  },
  async mounted() {
    this.ready = true;
    await this.$refs.table.reset();
    this.getEnumPurchaseRateListResponseAll();
    this.getEnumClientActionMethodList();
    this.getCampaignListResponseAll();
    this.getEnumCampaignGroupListResponseAll();
    this.getEnumPrefectureList();
  },
  computed: {
    ...mapGetters([
      'getActionWithActionTypeResponseAll',
      'getEnumPrefeList',
      'customerLengthPDF',
      'clientSearchPaginatedResponseAll',
      'enumPurchaseRateListResponseAll',
      'picIdsResponseAll',
      'arrPurchaseRatesSelectResponseAll',
      'arrPicIdsResponseAll',
      'includeDoNotContactResponseAll',
      'lastResponseDateFromResponseAll',
      'lastResponseDateFromResponseAll',
      'membershipStatusResponseAll',
      'membershipCancellationDateFromResponseAll',
      'membershipCancellationDateToResponseAll',
      'lastActionDateToResponseAll',
      'lastActionDateFromResponseAll',
      'enumClientActionMethodListApplicant',
      'listEnumClientActionMethodListResponseAll',
      'hasActionMethodIdsTypeResponseAll',
      'listCampaignListResponseAll',
      'campaignListResponseAll',
      'listCampaignGroupsResponseAll',
      'campaignListGroupsResponseAll',
      'itemCampaignTimesResponseAll',
      'hasTrialStayResponseAll',
      'remarksResponseAll',
      'clientContactTypeResponseAll',
      'contactPostalCodesResponseAll',
      'contactPrefecturesResponseAll',
      'contactLocalitiesResponseAll',
      'brochureRequetTypeResponseAll',
      'includeCompanyResponseAll',
      'clientIdsResponseAll',
      'sexResponseAll',
      'ageFromResponseAll',
      'ageToResponseAll',
      'enumCampaignGroupListResponseAll',
    ]),
    // filter getPurchaseRatesResponseAllSelect
    getPurchaseRatesResponseAllSelect: {
      get() {
        return this.arrPurchaseRatesSelectResponseAll;
      },
      set(value) {
        this.setPurchaseRatesSelectResponseAll(value);
      },
    },
    // filter picIdsResponseAll
    getPicIdsResponseAll: {
      get() {
        return this.arrPicIdsResponseAll;
      },
      set(value) {
        this.setListPicIdsResponseAll(value);
      },
    },
    // filter includeDoNotContactResponseAll
    getIncludeDoNotContactResponseAll: {
      get() {
        return this.includeDoNotContactResponseAll;
      },
      set(value) {
        this.setIncludeDoNotContactResponseAll(value);
      },
    },
    // filter lastResponseDateFromResponseAll
    getLastResponseDateFromResponseAll: {
      get() {
        return this.lastResponseDateFromResponseAll;
      },
      set(value) {
        this.setLastResponseDateFromResponseAll(value);
      },
    },
    // filter lastResponseDateToResponseAll
    getLastResponseDateToResponseAll: {
      get() {
        return this.lastResponseDateToResponseAll;
      },
      set(value) {
        this.setLastResponseDateToResponseAll(value);
      },
    },
    // filter membershipStatusResponseAll
    getMembershipStatusResponseAll: {
      get() {
        return this.membershipStatusResponseAll;
      },
      set(value) {
        this.setMembershipStatusResponseAll(value);
      },
    },
    // filter membershipCancellationDateFromResponseAll
    getMembershipCancellationDateFromResponseAll: {
      get() {
        return this.membershipCancellationDateFromResponseAll;
      },
      set(value) {
        this.setMembershipCancellationDateFromResponseAll(value);
      },
    },
    // filter membershipCancellationDateToResponseAll
    getMembershipCancellationDateToResponseAll: {
      get() {
        return this.membershipCancellationDateToResponseAll;
      },
      set(value) {
        this.setMembershipCancellationDateToResponseAll(value);
      },
    },
    // filter lastActionDateToResponseAll
    getLastActionDateToResponseAll: {
      get() {
        return this.lastActionDateToResponseAll;
      },
      set(value) {
        this.setLastActionDateToResponseAll(value);
      },
    },
    // filter lastActionDateFromResponseAll
    getLastActionDateFromResponseAll: {
      get() {
        return this.lastActionDateFromResponseAll;
      },
      set(value) {
        this.setLastActionDateFromResponseAll(value);
      },
    },
    // filter enumClientActionMethodListResponseAll
    getEnumClientActionMethodListResponseAll: {
      get() {
        return this.listEnumClientActionMethodListResponseAll;
      },
      set(value) {
        this.setListClientActionMethodListResponseAll(value);
      },
    },
    // filter hasActionMethodIdsType
    getHasActionMethodIdsTypeResponseAll: {
      get() {
        return this.hasActionMethodIdsTypeResponseAll;
      },
      set(value) {
        this.setHasActionMethodIdsTypeResponseAll(value);
      },
    },
    // filter ListCampaignListResponseAll
    getListCampaignListResponseAll: {
      get() {
        return this.listCampaignListResponseAll;
      },
      set(value) {
        this.setListCampaignListResponseAll(value);
      },
    },
    actionWithActionType: {
      get() {
        return this.getActionWithActionTypeResponseAll;
      },
      set(value) {
        this.setActionWithActionTypeResponseAll(value);
      },
    },
    // filter listCampaignGroupsResponseAll
    getListCampaignGroupsResponseAll: {
      get() {
        return this.listCampaignGroupsResponseAll;
      },
      set(value) {
        this.setListCampaignGroupsResponseAll(value);
      },
    },
    // filter listCampaignGroupsResponseAll
    getItemCampaignTimesResponseAll: {
      get() {
        return this.itemCampaignTimesResponseAll;
      },
      set(value) {
        this.setItemCampaignTimesResponseAll(value);
      },
    },
    // filter HasTrialStayResponseAll
    getHasTrialStayResponseAll: {
      get() {
        return this.hasTrialStayResponseAll;
      },
      set(value) {
        this.setHasTrialStayResponseAll(value);
      },
    },
    // filter setRemarks
    getRemarksResponseAll: {
      get() {
        return this.remarksResponseAll;
      },
      set(value) {
        this.setRemarksResponseAll(value);
      },
    },
    // filter clientContactTypeResponseAll
    getClientContactTypeResponseAll: {
      get() {
        return this.clientContactTypeResponseAll;
      },
      set(value) {
        this.setClientContactTypeResponseAll(value);
      },
    },
    // filter contactPostalCodesResponseAll
    getContactPostalCodesResponseAll: {
      get() {
        return this.contactPostalCodesResponseAll;
      },
      set(value) {
        if (value.length > 0) {
          if (value[value.length - 1] !== ',') {
            var arr = value.split(',');
            this.setContactPostalCodesResponseAll(arr);
          }
        } else {
          this.setContactPostalCodesResponseAll(null);
        }
      },
    },
    // filter contactPrefectures
    getContactPrefecturesResponseAll: {
      get() {
        return this.contactPrefecturesResponseAll;
      },
      set(value) {
        this.setContactPrefecturesResponseAll(value);
      },
    },
    // filter contactPrefectures
    getContactLocalitiesResponseAll: {
      get() {
        return this.contactLocalitiesResponseAll;
      },
      set(value) {
        if (value.length > 0) {
          if (value[value.length - 1] !== ',') {
            var arr = value.split(',');
            this.setContactLocalitiesResponseAll(arr);
          }
        } else {
          this.setContactLocalitiesResponseAll(null);
        }
      },
    },
    // filter contactPrefectures
    getBrochureRequetTypeResponseAll: {
      get() {
        return this.brochureRequetTypeResponseAll;
      },
      set(value) {
        this.setBrochureRequetTypeResponseAll(value);
      },
    },
    // filter includeCompanyResponseAll
    getIncludeCompanyResponseAll: {
      get() {
        return this.includeCompanyResponseAll;
      },
      set(value) {
        this.setIncludeCompanyResponseAll(value);
      },
    },
    // filter sexResponseAll
    getSexResponseAll: {
      get() {
        return this.sexResponseAll;
      },
      set(value) {
        this.setSexResponseAll(value);
      },
    },
    // filter ageFromResponseAll
    getAgeFromResponseAll: {
      get() {
        return this.ageFromResponseAll;
      },
      set(value) {
        if (value.length === 0) {
          this.setAgeFromResponseAll(null);
        } else {
          this.setAgeFromResponseAll(value);
        }
      },
    },
    // filter ageToResponseAll
    getAgeToResponseAll: {
      get() {
        return this.ageToResponseAll;
      },
      set(value) {
        if (value.length === 0) {
          this.setAgeToResponseAll(null);
        } else {
          this.setAgeToResponseAll(value);
        }
      },
    },
    // filter memberIdsApplicant
    getMemberIdsApplicant: {
      get() {
        return this.memberIdsApplicant;
      },
      set(value) {
        if (value && value.length > 0) {
          this.filter.memberIds = value.split(',').map(v => v?.trim()).filter((v) => !!v);
        } else {
          this.filter.memberIds = [];
        }
      },
    },
  },
  watch: {
    clientIdsText(value) {
      if (value) {
        var arr = value.split(',').map(Number);
        this.setClientIdsResponseAll(arr.filter(v => !isNaN(v)));
      } else {
        this.setClientIdsResponseAll(null);
      }
    },
  },
  methods: {
    async loadList(variables) {
      if(variables){
        const { total } = await this.getClientSearchPaginatedResponseAll({
          take: variables.pagination.take,
          skip: variables.pagination.skip,
          orderBy: variables.orderBy,
          filter: this.filter
        });
        this.totalCount = total;
      }
    },

    getCopyErrorTextView,
    downloadFile,
    getAgeFromDate,
    ...mapActions([
      'getClientSearchPaginatedResponseAll',
      'getEnumPurchaseRateListResponseAll',
      'getEnumClientActionMethodList',
      'getCampaignListResponseAll',
      'getEnumCampaignGroupListResponseAll',
      'getEnumPrefectureList',
    ]),
    ...mapMutations([
      'setCustomerLengthPDF',
      'setCopyErrorText',
      'setActionWithActionTypeResponseAll',
      'setEnumPrefeList',
      'setPurchaseRatesSelectResponseAll',
      'setListPicIdsResponseAll',
      'setIncludeDoNotContactResponseAll',
      'setLastResponseDateFromResponseAll',
      'setLastResponseDateToResponseAll',
      'setMembershipStatusResponseAll',
      'setMembershipCancellationDateFromResponseAll',
      'setMembershipCancellationDateToResponseAll',
      'setLastActionDateToResponseAll',
      'setLastActionDateFromResponseAll',
      'setListClientActionMethodListResponseAll',
      'setHasActionMethodIdsTypeResponseAll',
      'setListCampaignListResponseAll',
      'setListCampaignListResponseAll',
      'setListCampaignGroupsResponseAll',
      'setItemCampaignTimesResponseAll',
      'setHasTrialStayResponseAll',
      'setRemarksResponseAll',
      'setClientContactTypeResponseAll',
      'setContactPostalCodesResponseAll',
      'setContactPrefecturesResponseAll',
      'setContactLocalitiesResponseAll',
      'setBrochureRequetTypeResponseAll',
      'setIncludeCompanyResponseAll',
      'setClientIdsResponseAll',
      'setSexResponseAll',
      'setAgeFromResponseAll',
      'setAgeToResponseAll',
    ]),

    setDefaultData() {
      this.setCustomerLengthPDF(0);
      this.checkIdStartPage();
      this.visible = 1;
    },

    async downloadCsv() {
      if (this.selected.map(e => e.id).length > 0) {
        const query = `
              mutation ($clientIds: [Int!]!) {
                clientListCsv(clientIds: $clientIds) {
                  files {
                    fileName
                    downloadUrl
                  }
                }
              }
            `;
        const variables = {
          clientIds: this.selected.map(e => e.id),
        };
        await this.$apollo
          .mutate({
            mutation: gql`
              ${query}
            `,
            variables: variables,
            fetchPolicy: 'no-cache',
          })
          .then(data => {
            this.downloadFile(
              data.data.clientListCsv.files[0].downloadUrl,
              data.data.clientListCsv.files[0].fileName,
            );
          })
          .catch(async error => {
            this.setCopyErrorText(
              this.getCopyErrorTextView(query, variables, error.graphQLErrors),
            );
            const errorTmp = await handlErrorView(
              error.graphQLErrors,
              this.setPermissionUser,
              this.setRoleAdminUser,
            );
            if (errorTmp) {
              this.setAlertError(errorTmp, { root: true });
            } else {
              this.setAlertError('削除に失敗しました');
            }
          });
      }
    },

    getAge(birthday) {
      var age = new Date().getFullYear() - Number(birthday.substr(0, 4));
      return age;
    },
    check(e) {
      if (e.key === '-') {
        e.preventDefault();
      }
    },
    checkPostalCode(val) {
      if (val) {
        if (val[0].length === 7 || val[0].length === 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    checkSelectedList(arr) {
      const newArr = [];
      const newArrId = [];
      arr.forEach(element => {
        newArr.push(element.name);
        newArrId.push(element.id);
      });
      this.selectedList = newArr;
      this.setListCampaignListResponseAll(newArrId);
    },

    checkIdStartPage() {
      var arr = [];
      if (this.customerLengthPDF > 0) {
        for (let i = 1; i < this.customerLengthPDF; i++) {
          arr.unshift({
            birthday: '',
            companyPicDepartment: '',
            companyPicName: '',
            companyPicTitle: '',
            contracts: '',
            doNotContact: '',
            id: '',
            isCompany: '',
            mainContact: '',
            memberId: '',
            membershipCancellationDate: '',
            name: '',
            pic: '',
            purchaseRate: '',
            type: '',
          });
        }
      }
      this.selected.forEach(e => {
        arr.push(e);
      });
      this.dataSelect = arr;
      console.log('dataSelect', this.dataSelect);

      for (let i = 0; i < this.customerLengthPDF; i++) {
        if (this.arrIdEndPage.length === 0) {
          this.arrIdEndPage.push(11);
        } else {
          this.arrIdEndPage.push(this.arrIdEndPage[i - 1] + 12);
        }
      }
    },
    updateMembershipCancelDate(item) {
      if (item.id === 1) {
        this.getMembershipCancellationDateFromResponseAll = item.date;
      } else {
        this.getMembershipCancellationDateToResponseAll = item.date;
      }
    },
    updatLastResponseDate(item) {
      if (item.id === 1) {
        this.dateLastResponseDateFrom[0].date = item.date;
        this.getLastResponseDateFromResponseAll = item.date;
      } else {
        this.dateLastResponseDateFrom[1].date = item.date;
        this.getLastResponseDateToResponseAll = item.date;
      }
    },
    updatLastActionDate(item) {
      if (item.id === 1) {
        this.dateActivityInformation[0].date = item.date;
        this.getLastActionDateFromResponseAll = item.date;
      } else {
        this.dateActivityInformation[1].date = item.date;
        this.getLastActionDateToResponseAll = item.date;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.text--title {
  font-size: 18px;
  font-weight: 600;
}
.v-card-custom {
  border: 1px solid white;
}
.from {
  color: #000000;
  font-size: 12px;
  white-space: nowrap;
}
::v-deep {
  .v-text-field-number {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
  .v-select__selection {
    color: #000000;
  }
  .v-input--checkbox {
    .v-label {
      font-size: 12px !important;
      color: #000000 !important;
    }
  }
  .v-input__control {
    .v-label {
      font-size: 12px !important;
      color: #888888;
    }
    .v-label--active {
      font-size: 8px !important;
      color: #000000;
    }
  }
  .v-dialog {
    border-radius: 9px;
  }
  .float-left {
    .v-select__selection {
      color: #000;
    }
  }
  .v-data-table {
    table tr th,
    table tr td {
      color: #757575 !important;
    }
  }
}
.keep-text {
  word-break: keep-all;
}
// .advertising-measures-customer-dialog {
// ::v-deep {
// }
// }
.search-btn-text {
  font-size: 12px;
}
.outlined-btn-text {
  font-size: 10px;
}
.combobox {
  ::v-deep {
    .v-select__slot {
      cursor: pointer !important;
      .v-label--active {
        font-size: 8px !important;
      }
    }
  }
}
div.div-postalCode {
  min-height: 22px;
  font-size: 10px;
}
div.div-address {
  min-height: 50px;
}
div#pdf {
  position: absolute;
  left: -1000000px;
  display: none;
  width: 757.13px;
  min-height: 1070.79px;
  // border: 1px solid black;
  padding: 80.13px 35.3px 82.02px 35.3px;
}
.div-item {
  // border: 1px solid black;
  border-radius: 10px;
  padding: 0px 10px;
  margin: 0px !important;
  min-height: 165px !important;
  width: 326.55 !important;
  font-size: 10px;
}
</style>
<style lang="scss">
.pdf {
  &_item {
    position: relative;
    &_id {
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
  }
}
@media print {
  .pdf {
    &_item {
      position: relative;
      &_id {
        position: absolute;
        bottom: 15px;
        right: 15px;
      }
    }
  }
}
</style>

<style lang="scss">
html {
  scroll-behavior: smooth;
}
.no-pagination {
  .v-data-footer__pagination {
    display: none;
  }
}
.custom-checkbox-label {
  label {
    font-size: 12px !important;
    color: #000000 !important;
  }
}
.custom-input-label {
  .v-input__slot {
    .v-label {
      color: #888888 !important;
      font-size: 12px;
    }
    .v-label--active {
      color: #000000 !important;
      font-size: 8px;
      font-weight: bold;
    }
    input {
      color: #000;
    }
  }
}
</style>
